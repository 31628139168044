import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';


const PrivateRoutes = () => {
  
  let jwt_token = localStorage.getItem('jwt_token_springboard')
 

  return (
    jwt_token != null ? <Outlet/> : <Navigate to='/SignIn'/>
  )
}

export default PrivateRoutes;