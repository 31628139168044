// store.js
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt'; // import encryptTransform
import storage from 'redux-persist/lib/storage/session'; // defaults to localStorage for web
import UserSlice from './slices/UserSlice/UserSlice';
import CourseSlice from './slices/CourseSlice/CourseSlice';

const encryptionKey = 'DSASpringboardInc';

// Encryption configuration
const encryptor = encryptTransform({
  secretKey: encryptionKey,
  onError: function (error) {
    console.error('Encryption error:', error);
  },
});


// Persist configuration
const persistConfig = {
  key: 'root',
  storage, // use localStorage
  transforms: [encryptor],
};

// Wrap each slice reducer with persistReducer
const persistedUserReducer = persistReducer(persistConfig, UserSlice);
// const persistedCourseReducer = persistReducer(persistConfig, CourseSlice);

// Configure the store with combined persisted reducers
const store = configureStore({
  reducer: {
    User: persistedUserReducer,
    Course: CourseSlice,
  },
});

// Create persistor
const persistor = persistStore(store);

export { store, persistor };
