import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import { 
    Grid,
    Typography,
    TextField,
    IconButton ,
    InputAdornment,
    Snackbar,
} from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { useSelector, useDispatch } from 'react-redux'
import { ConfirmAccountFunction } from '../../Redux/slices/UserSlice/UserAPI';
import  SpringBoardLogo  from '../../assets/SpringBoardIncubatorsLogo.png';
import { useNavigate } from 'react-router-dom';

import { Divider } from 'semantic-ui-react';
import { Button } from 'semantic-ui-react'

const MainContainer = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh', // Ensure it takes full viewport height  
}

const SignUpOuterContainer = {
  boxShadow: '5px 10px 18px #888888',
  maxWidth: '60%',
  height: '90vh',
  borderRadius: '3rem'
}

const ImageContainer = {
  backgroundColor: '#9BEDCC',
  borderRadius: '0 3rem 3rem 0'
}

const ImageInnerContainer = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  // display: 'flex', 
  // flexDirection: 'column',
  // justifyContent: 'center',
  // alignItems: 'center',
  // height: '100%',  // Ensure the container takes up the full height of its parent

}

const FormContainer = {
  display: 'flex',
  // flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}

const HeaderTextStyle = {
 textAlign: 'center',
 marginBottom: '1rem',
 fontSize: '1.3rem' 
}

const InnerFormContainer = {
 
}

const TextfieldStyle = {
  width: '120%',
  height: '3.5rem',
  marginBottom: '1rem'
}

const DropdownStyle = {
  width: '120%',
  marginBottom: '1rem'   
}

const SignUpButtonStyle = {
 width: '120%',
 textTransform: 'none'
}
   
const TextStyle = {
  fontSize: '1.1rem',
  fontWeight: '500',
  marginTop: '0.7rem'
}

const SpringBoardLogoStyle = {
  width: '17rem',
  height: '5rem',
}

const SpringBoardSmallLogoStyle = {
  width: '5rem',
  height: '5rem',
  marginTop: '0.7rem'
}

const ConfirmAccount = () => {
  
 const dispatch = useDispatch()
 const navigate = useNavigate();
 const [email, setEmailid] = useState('');
 const [password, setPassword] = useState('');
 const [showPassword, setShowPassword] = React.useState(false);


 const [ErrorFlagEmail, setErrorFlagEmailid] = useState(true);
 const [ErrorFlagPassword, setErrorFlagPassword] = useState(false);


 const handleClickShowPassword = () => setShowPassword((show) => !show);
 const [open, setOpen] = useState(false);
 let message = useSelector(state => state.User?.message);
 let success = useSelector(state => state.User?.success);


 useEffect(() => {

  if(success) {
      // Redirect to a different page upon successful sign-up

     // navigate('/signin');
     const timer = setTimeout(() => {
      navigate('/signin'); // Replace with your target route
    }, 3000); // 3000 milliseconds = 3 seconds

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }
}, [success,navigate]);
 const handleClick = () => {

    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('q');

    setOpen(true);

  dispatch(
    ConfirmAccountFunction({
      id, 
    
    })
  )
 };
 
 return (
  <>
   <Grid container style={MainContainer} xs={12} sm={12} md={12}>
   <Grid item style={FormContainer} xs={12} sm={12} md={12}>
      <Card style={{alignContent:'center'}}sx={{ maxWidth: 700 }}>
      {/* <CardMedia
        sx={{ height: 100 }}
        image="/Users/mshah/Documents/Springboard/dsa_front_end/src/assets/SpringBoardIncubatorsLogo.png"
        title=""
      /> */}
       <div style={{backgroundColor:'#9BEDCC',height:90}}>
       <img src={SpringBoardLogo} style={SpringBoardLogoStyle}/>
       </div>
      
      <CardContent sx={{height:180}}>
        <Typography style={{textAlign:'center'}}gutterBottom variant="h5" component="div">
          <b>Confirm Your Account</b>
        </Typography>
        <Divider/>
        <br></br>
        <Typography variant="h5" color="text.secondary">
          Click on the button below to confirm your account
        </Typography>
      </CardContent>
      <CardActions style={{paddingLeft:'42%'}}>
      <Button sx={{  width: '120%',textTransform: 'none' }} onClick={handleClick} primary>Confirm</Button>    
      
      </CardActions>
    </Card>

      
        </Grid>
        <Snackbar
         anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
         open={open}
         severity="warning"
         autoHideDuration={2000}
         message={message}        
         //  action={action}
       />
   </Grid>
  </>  
 )   
}

export default ConfirmAccount;