import { createSlice } from '@reduxjs/toolkit';
import { 

  CoursePaymentFunction,
  CourseListFunction,
  CourseDetailFunction,
  CourseVideoProgress,
  SaveCourseProgress

} from './CourseAPI';

const initialState = {
  loading: false,
  message: '',
  purchased_course_data: [],
  course_data: [],
  my_course_data: [],
  purchase_url: '',
  course_detail: null,
  success: false  
}

export const CourseSlice = createSlice({
  name: 'Course',
  initialState,
  reducers: {
    clearState(state) {
      return initialState; // Reset to the initial state
    }
  },
  
  extraReducers: (builder) => {
    builder
      .addCase(CourseListFunction.pending, (state) => {
     
       state.loading = true;
      })
      .addCase(CourseListFunction.fulfilled, (state, action) => {
     
       state.loading = false;
       state.course_data = action?.payload?.data?.Course_list
       state.purchased_course_data = action.payload?.data?.Course_list?.coursesWithPurchasedDetails
       state.success = true
       return;
      })
      .addCase(CourseListFunction.rejected, (state, action) => {
     
       if(action.error.code=='ERR_BAD_REQUEST' && action.error.message=='Request failed with status code 403'){
        localStorage.removeItem('jwt_token_springboard')
         window.location.reload()
       }
      
       state.loading = false;
       state.course_data = []
       state.purchased_course_data = []
       state.message = action.payload?.data.error;
       state.success = false;
      
      })
      .addCase(CourseDetailFunction.pending, (state) => {
        
        state.loading = true;
      })
      .addCase(CourseDetailFunction.fulfilled, (state, action) => {
      
        state.loading = false;
        state.course_detail = action.payload?.data
        state.success = true
        return;
      })
      .addCase(CourseDetailFunction.rejected, (state, action) => {
       
        if(action.error.code=='ERR_BAD_REQUEST' && action.error.message=='Request failed with status code 403'){
          localStorage.removeItem('jwt_token_springboard')
           window.location.reload()
         }
  
    
        state.loading = false;
        state.message = action.payload?.data.error;
        state.success = false;
     
      
      }) 
      .addCase(CoursePaymentFunction.fulfilled, (state, action) => {
      
        state.loading = false;
        state.purchase_url = `${action.payload?.data.approvalUrl}`
        state.success = true
        return;
      })
      .addCase(CoursePaymentFunction.rejected, (state, action) => {
        if(action.error.code=='ERR_BAD_REQUEST' && action.error.message=='Request failed with status code 403'){
          localStorage.removeItem('jwt_token_springboard')
           window.location.reload()
         }
       
        state.loading = false;
        state.message = action.payload?.data.error;
        state.success = false;
      
      })
      .addCase(CourseVideoProgress.pending, (state) => {
        
        state.loading = true;
       })
       .addCase(CourseVideoProgress.fulfilled, (state, action) => {
      
        state.loading = false;
      
        state.success = true
        return;
       })
       .addCase(CourseVideoProgress.rejected, (state, action) => {
       
        state.loading = false;
        
        state.success = false;
       
       })

       .addCase(SaveCourseProgress.pending, (state) => {
        
        state.loading = true;
       })
       .addCase(SaveCourseProgress.fulfilled, (state, action) => {
      
        state.loading = false;
      
        state.success = true
        return;
       })
       .addCase(SaveCourseProgress.rejected, (state, action) => {
       
        state.loading = false;
        
        state.success = false;
       
       })
  },
})

// Action creators are generated for each case reducer function
// export const { SignUpFunction } = UserSlice.actions
export const {  clearState } = CourseSlice.actions;

export default CourseSlice.reducer

