import React, { createContext, useState, useEffect } from 'react';
import { useSelector,useDispatch } from 'react-redux';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
    
  const [cartItems, setCartItems] = useState(JSON.parse(localStorage.getItem('cartItems')) || []);
  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  const addToCart = (newItem) => {
    console.log('in add to cart',newItem)
    
    setCartItems((prevItems) => { 
      const itemExists = prevItems.some(item => item.course_id === newItem.course_id);
      if (!itemExists) {
        return [...prevItems, newItem];
      } else {
        return prevItems;
      }
    });
  };

//   const removeFromCart = (itemId) => {
//     console.log('removefrom cart.......................',itemId);
   
//     setCartItems((prevItems) => prevItems.filter(item => item.course_id !== itemId.course_id));
//     console.log('cartItems',cartItems)
//   };

const removeFromCart = (itemId) => {
    console.log('remove from cart:', itemId);
  
    setCartItems((prevItems) => {
      if (Array.isArray(itemId)) {
        // If itemId is an array, filter out all items that match any course_id in the array
        const itemIdsToRemove = itemId.map(item => item.course_id);
        return prevItems.filter(item => !itemIdsToRemove.includes(item.course_id));
      } else {
        // If itemId is a single object, remove item with matching course_id
        return prevItems.filter(item => item.course_id !== itemId.course_id);
      }
    });
    
    console.log('updated cartItems:', cartItems);
  };

  return (
    <CartContext.Provider value={{ cartItems, addToCart, removeFromCart }}>
      {children}
    </CartContext.Provider>
  );
};