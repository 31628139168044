import React from 'react';


const environment = {

//ServerURL: 'http://localhost:3000/api',
ServerURL: 'https://app.sbelearning.com/api'
//    baseURL: 'http://localhost:3006',
//   documentURL: 'http://localhost:1340/',

};
export default environment;
