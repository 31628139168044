import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import environment from '../../../environment'


  export const CoursePaymentFunction = createAsyncThunk(
    'api/BuyCourse',
    async ({ token,course_ids }) => {


    
      const config = {
        headers: {
          
          'Authorization':`Bearer ${token}`, // Replace with actual token
          'Content-Type': 'application/json',
          // Add more headers as needed
        }
      };
       
      const res = await axios.post(
        `${environment.ServerURL}/purchaseCourse`,
            { 
              token,
              course_ids
            },
            config
      )
  
     
        
      return res  
    }
  )

  export const MyCourseFunction = createAsyncThunk(
    'api/mycourse',
    async () => {

      const token = localStorage.getItem('jwt_token_springboard');
     
      const config = {
        headers: {
          
          'Authorization':`Bearer ${token}`, // Replace with actual token
          'Content-Type': 'application/json',
          // Add more headers as needed
        }
      };
       
      const res = await axios.get(
        `${environment.ServerURL}/myCourse`,
        config
      )
  
   
      return res  
    }
  )

  export const CourseDetailFunction = createAsyncThunk(
    'api/courseDetail',
    async ({ course_id }) => {

       
      const res = await axios.get(`${environment.ServerURL}/courseDetail?id=${course_id}`)
          
      return res  
    }
  )
  
  export const CourseAllListFunction = createAsyncThunk(
    'api/courseAllList',
    async () => {
      
       
      const res = await axios.get(
        `${environment.ServerURL}/courseAllList`,
        
      )
        
      return res  
    }
  )

  export const CourseListFunction = createAsyncThunk(
    'api/ListCourse',
    async () => {
      const token = localStorage.getItem('jwt_token_springboard');
    
      const config = {
        headers: {
          
          'Authorization':`Bearer ${token}`, // Replace with actual token
          'Content-Type': 'application/json',
          // Add more headers as needed
        }
      };

       
      const res = await axios.get(
        `${environment.ServerURL}/courseList`,
        config
      )
       
      return res  
    }
  )

  export const CourseVideoProgress = createAsyncThunk(
    'api/getProgress',
    async ({ courseId }) => {
      const token = localStorage.getItem('jwt_token_springboard');
   
      const config = {
        headers: {
          
          'Authorization':`Bearer ${token}`, // Replace with actual token
          'Content-Type': 'application/json',
          // Add more headers as needed
        }
      };
       
      const res = await axios.post(
        `${environment.ServerURL}/getProgress`,
            { 
              
              courseId
            },
            config
      )
  
    
        
      return res  
    }
  )

  export const SaveCourseProgress = createAsyncThunk(
    'api/saveProgress',
    async ({ payload }) => {
      console.log('payload in save progress',payload)
     // const token = localStorage.getItem('jwt_token_springboard');
   
      const config = {
        headers: {
          
         // 'Authorization':`Bearer ${token}`, 
          'Content-Type': 'application/json',
          // Add more headers as needed
        }
      };
       
      const res = await axios.post(
        `${environment.ServerURL}/saveProgress`,payload,
            { 
              
              config
            },
            
      )
  
    
        
      return res  
    }
  )

