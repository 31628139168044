import { useEffect ,useState} from 'react';
import 'semantic-ui-css/semantic.min.css';
import { 
  Routes,
  Route
} from "react-router-dom";
import MyCoursePage from "./screens/MyCoursePage/MyCoursePage";
import VideoStreamingPage from './screens/VideoStreamingPage/VideoStreamingPage';
import SignInPage from './screens/SignInPage/SignInPage';
import SignUpPage from './screens/SignUpPage/SignUpPage';
import ConfirmAccount from './screens/ConfirmAccount/ConfirmAccount';
import ForgetPasswordPage from './screens/ForgetPasswordPage/ForgetPasswordPage';
import BuyCoursePage from './screens/BuyCoursePage/BuyCoursePage';
import PrivateRoutes from './utils/PrivateRoutes/PrivateRoutes';
import ShoppingCart from './screens/ShoppingCart/ShoppingCart'
import Layout from './screens/Layout/Layout';
import MainLayout from './screens/Layout/MainLayout';
import CourseDetail from './screens/MyCoursePage/CourseDetail';
import {CartProvider} from './screens/ShoppingCart/CartContext'
const App = () => {

  return (
    <>
     <CartProvider>
     <Routes>
     <Route path="/" element={<MainLayout />} />
   
      <Route path="/signin" element={<SignInPage />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="/forgetpassword" element={<ForgetPasswordPage />} />
      <Route path="/cart" element={<ShoppingCart />} />
      <Route path="/confirmAccount" element={<ConfirmAccount />} />
      <Route path="/courseDetail" element={<CourseDetail />} />
      <Route element={<PrivateRoutes/>}>
        <Route path='/mycourse' element={<MyCoursePage/>} />
        <Route path='/VideoStreamingPage' element={<VideoStreamingPage/>} />
      </Route>
     </Routes>
     </CartProvider>
   </>
  );
}

export default App;
