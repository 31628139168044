import { AppBar, Button, ThemeProvider, Toolbar, styled, useTheme,Box } from "@mui/material";

const FooterContainer = styled(Box)(({ theme }) => ({
    position: "fixed",
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#275317", // Footer background color
    padding: "10px 20px",
    textAlign: "center",
    color: "#fff",
  }));
export default function Footer() {
  const theme = useTheme();



  return (
    <div>
      <FooterContainer>
    <p>© 2024 SpringBoard Incubators. All rights reserved.</p>
  </FooterContainer>
    </div>
  );
}

