import * as React from 'react';
import { useState,useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Card, Checkbox, Grid, TextField, Box, styled, useTheme ,Snackbar,Typography,MenuItem,InputAdornment,IconButton} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Formik } from "formik";
import * as Yup from "yup";
import { SignInFunction, SignInGoogleFunction } from '../../Redux/slices/UserSlice/UserAPI';
import { Paragraph } from "../../Components/Typography";
import { useSelector, useDispatch } from 'react-redux'
import { resetSuccess } from '../../Redux/slices/UserSlice/UserSlice';
import { SignUpFunction } from '../../Redux/slices/UserSlice/UserAPI';
import {resetSignUp} from '../../Redux/slices/UserSlice/UserSlice';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
// STYLED COMPONENTS
const FlexBox = styled(Box)(() => ({
  display: "flex"
}));

const ContentBox = styled("div")(() => ({
  height: "100%",
  padding: "32px",
  position: "relative",
 // background: "rgba(0, 0, 0, 0.01)"
}));
const HeaderTextStyle = {
  textAlign: 'center',
  marginBottom: '3rem',
  fontSize: '1.3rem',
  marginTop:'17px',
  color:'black'
 }
const StyledRoot = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
 // backgroundColor: "#275317",
  minHeight: 812,
  //minHeight: "100% !important",
  "& .card": {
    maxWidth: 800,
   // minHeight: 400,
    margin: "1rem",
    display: "flex",
    borderRadius: 12,
    alignItems: "center"
  },

  ".img-wrapper": {
    height: "100%",
    minWidth: 320,
    display: "flex",
    padding: "1rem",
    alignItems: "center",
    justifyContent: "center"
  }
}));

// initial login credentials
const initialValues = {
  firstName:"",
  lastName:"",
  email: "",
  password: "",
  confirmPassword:"",
  profession:""
  
};

// form field validation schema
const validationSchema = Yup.object().shape({
  firstName:Yup.string()
  .required("First Name is required")
  .matches(/^[A-Za-z]+$/, "First name should contain only letters"),
  lastName:Yup.string()
  .required("Last Name is required")
  .matches(/^[A-Za-z]+$/, "Last name should contain only letters"),
  password: Yup.string()
    .min(6, "Password must be 6 character length")
    .required("Password is required!"),
  email: Yup.string().email("Invalid Email address").required("Email is required!").matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/,"Invalid Email address"),
  confirmPassword: Yup.string()
  .required("Confirm password is required")
  .oneOf([Yup.ref('password'), null], "Passwords must match"),
  profession: Yup.string().required("Please select an option")
  
});

export default function Signup() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  let message = useSelector(state => state.User?.message);
  let success = useSelector(state => state.User?.success);
  let status = useSelector(state => state.User?.status);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

console.log('show password',showPassword)
  useEffect(() => {
    if(status == 300 && success==false){

    }
    if(success == "true" && status=="200") {
     
      const timer = setTimeout(() => {
        navigate('/signin');
      }, 10000);
      return () => clearTimeout(timer);
       
    }
    // setTimeout(() => {
    //   dispatch(resetSignUp());
    // }, 1000);
  }, [success,navigate]);

  useEffect(() => {
    // Cleanup function to reset success when component unmounts
    return () => {
      dispatch(resetSuccess());
    };
  },[dispatch]);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    
    try {
      let firstName = values.firstName
      let lastName = values.lastName
      let email = values.email
      let password = values.password
      let confirmPassword = values.confirmPassword
      let profession = values.profession
      setOpen(true);

     await dispatch(
      SignUpFunction({
        firstName,
        lastName,
        email,
        password,
        confirmPassword,
        profession,
        isVerified: false
        })
      )
      // await login(values.email, values.password);
    
      //navigate("/");
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <StyledRoot>
      <Card  sx={{
        boxShadow: "5px 10px 18px #888888", 
        borderRadius: 3, 
       
      }}className="card">
        <Grid container>
          <Grid style={{backgroundColor:'#275317'}}item sm={6} xs={12}>
            <div className="img-wrapper">
              <img src="WhiteSpringBoardLogo.png" width="100%" alt="" />
            </div>
           
          </Grid>

          <Grid item sm={6} xs={12}>
         
            <ContentBox>
            <Typography sx={HeaderTextStyle}><b>START LEARNING TODAY—JOIN OUR eLEARNING PLATFORM!</b></Typography>
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
              >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                      <TextField
                      fullWidth
                      size="small"
                      type="firstName"
                      name="firstName"
                      label="First Name"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.firstName}
                      onChange={handleChange}
                      helperText={touched.firstName && errors.firstName}
                      error={Boolean(errors.firstName && touched.firstName)}
                      sx={{ mb: 3 }}
                    />
                     <TextField
                      fullWidth
                      size="small"
                      type="lastName"
                      name="lastName"
                      label="Last Name"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.lastName}
                      onChange={handleChange}
                      helperText={touched.lastName && errors.lastName}
                      error={Boolean(errors.lastName && touched.lastName)}
                      sx={{ mb: 3 }}
                    />
                    <TextField
                      fullWidth
                      size="small"
                      type="email"
                      name="email"
                      label="Email"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.email}
                      onChange={handleChange}
                      helperText={touched.email && errors.email}
                      error={Boolean(errors.email && touched.email)}
                      sx={{ mb: 3 }}
                    />
                    

                    <TextField
                      fullWidth
                      size="small"
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      //type="password"
                      label="Password"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.password}
                      onChange={handleChange}
                      helperText={touched.password && errors.password}
                      error={Boolean(errors.password && touched.password)}
                      sx={{  mb: 3 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                         ),
                        }}
                    />

                    <TextField
                      fullWidth
                      size="small"
                      name="confirmPassword"
                      type={showConfirmPassword ? 'text' : 'password'}
                      label="Confirm Password"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.confirmPassword}
                      onChange={handleChange}
                      helperText={touched.confirmPassword && errors.confirmPassword}
                      error={Boolean(errors.confirmPassword && touched.confirmPassword)}
                      sx={{ mb: 3 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowConfirmPassword}
                              // onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                         ),
                        }}
                    />
                    <TextField
                    name="profession"
                    fullWidth
                    size="small"
                    select
                    label="Select profession"
                    value={values.profession}
                    sx={{ mb: 1.5 }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(errors.profession && touched.profession)}
                    helperText={touched.profession && errors.profession}
                  >
                      {/* <MenuItem value="">
                                  Select a profession
                                </MenuItem> */}
                    <MenuItem value="Student">Student</MenuItem>
                    <MenuItem value="Professional">Professional</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </TextField>

                    <LoadingButton
                      type="submit"
                      //color="primary"
                      //loading={loading}
                      variant="contained"
                      style={{backgroundColor: "#275317"}}
                      sx={{ my: 2 }}
                    >
                      Register
                    </LoadingButton>

                    <Paragraph>
                      Already have an account?
                      <NavLink
                        to="/signin"
                        style={{ color: theme.palette.primary.main, marginLeft: 5 }}
                      >
                        Login
                      </NavLink>
                    </Paragraph>
                  </form>
                )}
              </Formik>
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={open}
                severity="warning"
                autoHideDuration={2000}
                message={message}        
         //  action={action}
       />
            </ContentBox>
          </Grid>
        </Grid>
      </Card>
    </StyledRoot>
  );
}

