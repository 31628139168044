// import { useEffect ,useState} from 'react';
// import { 
    
//     AppBar,
//     Toolbar,
//     Typography,
//     Box,
//     IconButton,Paper,LinearProgress,  Divider,
//     Card,
//     Grid,
//     useTheme,
//     CardActions,
//     CardContent,
//     CardMedia,
//     Button,
   
// } from '@mui/material';
// import MemoryIcon from "@mui/icons-material/Memory";

// import { AccountCircle } from '@mui/icons-material';
// import { useSelector,useDispatch } from 'react-redux';
// import { useNavigate,useParams } from 'react-router-dom';
// import { MyCourseFunction } from '../../Redux/slices/CourseSlice/CourseAPI';
// import CourseImage from '../../assets/DataStructure.jpeg'
// import { styled } from '@mui/material/styles';
// import Layout from '../Layout/Layout';
// const BodyContent = styled(Box)(({ theme }) => ({
//   marginTop: "120px", 
//   overflowY: "auto",
//   height: "calc(100vh - 120px)", // Remaining height for content (adjust as needed)
 
// }));
// const ContentBox = styled("div")(({ theme }) => ({
//   margin: "85px",
//   [theme.breakpoints.down("sm")]: { margin: "16px" }
// }));


// const LastSectionContainer = {
//   padding: '5% 3% 6% 3%'
// }

// const CourseCompleteTextStyle = {
//   paddingTop: '2%'
// }

// const CourseHeaderStyle = {
//   fontSize: '1.1rem',
//   margin: '5% 3% 0 3%'
// }
// const CourseHeaderStyleForName = {
//   fontSize: '1 rem',
//   margin: '1% 3% 0 3%'
// }

// const MyCoursePage = () => {

//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const[myCourseData,setMyCourseData]=useState();
//   const [progress, setProgress] = useState(0);
//   const staticValue = 75;
//   useEffect(() => {

   
//     GetCourseList()
   
//    },[]) 
 
//    const GetCourseList = async () => {
   
//     const response = await dispatch(MyCourseFunction())
//    // window.location.reload();
//     console.log('response',response.payload.data.length)
//       if(response.error && response.error.code=='ERR_BAD_REQUEST' && response.error && response.error.message=='Request failed with status code 403'){
      
//         localStorage.removeItem('jwt_token_springboard')
//          window.location.reload()
//        }
//       else if(response.payload == undefined && !response.error){
//         setMyCourseData()
//        }
       
//      else {
//       console.log('response.payload.data',response.payload.data)
//       setMyCourseData(response.payload.data)
//      }
    
//   }
// const handleClickOfStart = (videoList,courseId) =>{
//   console.log('videolist',videoList);
//   console.log('courseid,id')
 
// navigate('/videoStreamingPage',{state:{videoList,courseId}})
// }

//  return (
//   <>
//   {/* <NavigationBarPage /> */}
//   <Box  sx={{
//             overflow: "hidden", // Prevent outer scroll
//             height: "100vh", // Ensure the height covers the viewport
//           }}>
//   <Layout />
//   <BodyContent>
//     <ContentBox>
//   <h2 style={{textAlign:'center'}}>My Course</h2>
//   <Divider style={{margin:'1%'}} />
//   <br></br>
//   <div style={{ width: '100%', padding: '0 5%' }}> {/* Adjusted the parent container to ensure proper page width */}
//   <Grid container spacing={6}>
//   {myCourseData && myCourseData.length==0 && <Grid item lg={4} md={4} sm={12} xs={12}>
//  <h3>No Course Purchased</h3>
//   </Grid>}
       
        
           
//           {myCourseData && myCourseData.length>0&& myCourseData.map((course, index) => (
//         <Grid item lg={4} md={4} sm={12} xs={12}>
//           <Card sx={{ border: "3px solid black", borderRadius: "0px", padding: "16px" }}>
//             <CardMedia
//               sx={{ height: 200 }}
//               image={course.courseDetails.course_picture}
//               title="green iguana"
//             />
//             <CardContent>
//               <div style={{ display: "flex" }}>
//                 {" "}
//                 <MemoryIcon fontSize="large" />
//                 <Typography gutterBottom variant="h5" component="div">
//                   <b>{course.courseDetails.course_name}</b>
//                 </Typography>
//               </div>
//               <Typography variant="body2" sx={{ color: "text.secondary" }}>
//               {course.courseDetails.course_description}
             
//               </Typography>
//             </CardContent>
//             <CardActions sx={{ justifyContent: "center" }}>
//             <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
//             <Box width="50%" mr={3}>
//                 <LinearProgress variant="determinate" value={course.overallProgress} /> {course.overallProgress}% complete
//               </Box>
//               {course.overallProgress === 0 ? (
//                 <Button onClick ={()=>handleClickOfStart(course.courseDetails.video_list,course.course)} color="success" 
//                 variant="outlined" 
//                 size="small"   >Start Course</Button>
                
//               ) : (
//                 <Button  onClick ={()=>handleClickOfStart(course.courseDetails.video_list,course.course)} color="success" 
//                 variant="outlined" 
//                 size="small">Continue</Button>
//               )}
//             </Box>
//             </CardActions>
//           </Card>
         
//         </Grid>
//       ))}
        
       
       
//       </Grid>
//   </div>
//   </ContentBox>
//   </BodyContent>
//   </Box>
// </>

//  )   
// }

// export default MyCoursePage;


import React, { useEffect, useState ,useContext} from 'react';
import {
  Box, Grid, Card, CardContent, CardMedia, Typography, Divider, Button,
  LinearProgress, CardActions, IconButton
} from '@mui/material';
import MemoryIcon from "@mui/icons-material/Memory";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MyCourseFunction } from '../../Redux/slices/CourseSlice/CourseAPI';
import Layout from '../Layout/Layout';
import { styled } from '@mui/material/styles';
import { CartContext } from '../ShoppingCart/CartContext';

const BodyContent = styled(Box)(({ theme }) => ({
  marginTop: "120px",
  overflowY: "auto",
  height: "calc(100vh - 120px)", // Remaining height for content
}));

const ContentBox = styled("div")(({ theme }) => ({
  margin: "85px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

const MyCoursePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [myCourseData, setMyCourseData] = useState([]);
  const { cartItems, removeFromCart } = useContext(CartContext);

  const [progress, setProgress] = useState(0);

  console.log('cart items in my course page',cartItems);
  console.log('mu course data in course page',myCourseData)
  useEffect(() => {
    GetCourseList();
  }, []);

  const GetCourseList = async () => {
    const response = await dispatch(MyCourseFunction());
    if (response.payload) {
      setMyCourseData(response.payload.data);
    }
  };

  const handleClickOfStart = (videoList, courseId) => {
    navigate('/videoStreamingPage', { state: { videoList, courseId } });
  };

  return (
    <Box sx={{ overflow: "hidden", height: "100vh" }}>
      <Layout />
      <BodyContent>
        <ContentBox>
          <Typography variant="h4" align="center">My Course</Typography>
          <Divider sx={{ marginY: 2 }} />
          <Box sx={{ width: '100%', padding: '0 5%' }}>
            <Grid container spacing={4} justifyContent="center">
              {myCourseData.length === 0 && (
                <Grid item xs={12} textAlign="center">
                  <Typography variant="h6">No Course Purchased</Typography>
                </Grid>
              )}
              {myCourseData.map((course, index) => (
                <Grid 
                  item 
                  xs={12} 
                  sm={myCourseData.length === 1 ? 6 : 4} 
                  md={myCourseData.length === 1 ? 6 : 4} 
                  key={index}
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Card sx={{ maxWidth: 400, width: '100%', border: "3px solid black", padding: "16px" }}>
                    <CardMedia
                      sx={{ height: 200 }}
                      image={course.courseDetails.course_picture}
                      title={course.courseDetails.course_name}
                    />
                    <CardContent>
                      <Box display="flex" alignItems="center">
                        <MemoryIcon fontSize="large" />
                        <Typography gutterBottom variant="h5" component="div" ml={1}>
                          <b>{course.courseDetails.course_name}</b>
                        </Typography>
                      </Box>
                      <Typography variant="body2" color="text.secondary">
                        {course.courseDetails.course_description}
                      </Typography>
                    </CardContent>
                    <CardActions sx={{ justifyContent: "center" }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Box width="50%" mr={3}>
                          <LinearProgress variant="determinate" value={course.overallProgress} />
                          {course.overallProgress}% complete
                        </Box>
                        {course.overallProgress === 0 ? (
                          <Button 
                            onClick={() => handleClickOfStart(course.courseDetails.video_list, course.course)} 
                            color="success" 
                            variant="outlined" 
                            size="small"
                          >
                            Start Course
                          </Button>
                        ) : (
                          <Button 
                            onClick={() => handleClickOfStart(course.courseDetails.video_list, course.course)} 
                            color="success" 
                            variant="outlined" 
                            size="small"
                          >
                            Continue
                          </Button>
                        )}
                      </Box>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </ContentBox>
      </BodyContent>
    </Box>
  );
};

export default MyCoursePage;
