import { 
    Grid,
    AppBar,
    Toolbar,
    Typography,
    Box,
    Button,
    IconButton 
} from '@mui/material';
import { AccountCircle, PaddingOutlined } from '@mui/icons-material';
import CourseImage from '../../assets/DataStructure.jpeg'
import { CoursePaymentFunction,CourseDetailFunction } from '../../Redux/slices/CourseSlice/CourseAPI';
import { useSelector,useDispatch } from 'react-redux';
import { useNavigate,useParams } from 'react-router-dom';
import { useEffect } from 'react';

const Navbar = {
 
 backgorundColor: 'transparent'   
}

const LoginLogoStyle = {
  height: '2.5rem',
  width: '2.5rem'  
}

const MyCourseButton = {
  paddingRight: '2rem'  
}


const MainContainer = {
  width: '100%',
  height: '100%'
}

const TitleTextStyle = {
 fontSize: '2.2rem'
}

const SubTitleTextStyle = {
 fontSize: '1.5rem'
}

const HeaderTextContainer = {
  margin: '12% 0 0 5%'
}


const HeaderContainer = {
  display: 'flex', 
  flexDirection: 'row',
  justifyContent: 'center', 
  marginTop: '5%'
}

const HeadingStyle = {
  fontSize: '2rem'
}

const CourseContainer = {
 boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
 borderRadius: '1.4rem'  
}

const CourseOuterContainer = {
  display: 'flex', 
  flexDirection: 'row',
  justifyContent: 'center', 
  width: '90%'
}

const CourseBoxContainer = {
  // marginRight: '1%'
}

const CourseImageStyle = {
  maxWidth: '30%',
  height: '3rem'
}


const LastSectionContainer = {
  padding: '5% 3% 6% 3%'
}

const CourseCompleteTextStyle = {
  paddingTop: '2%',
  fontSize: '1.5rem'
}

const CourseHeaderStyle = {
  fontSize: '2.3rem',
  margin: '5% 3% 0 3%'
}

const BannerContainer = {
  backgorundColor: 'black',
  width: '100%',
  height: '10vh'
}

const FirstLastContainer = {
  display: 'flex', 
  flexDirection: 'column',
  justifyContent: 'flex-start',   
}

const SecondLastContainer = {
  display: 'flex', 
  flexDirection: 'row',
  justifyContent: 'center', 
  width: '100%'  
}

const BuyNowButtonTextStyle = {
  fontSize: '1.2rem'
}

const ListTextStyle = {
  fontSize: '1.2rem'
}

const BuyButtonStyle = {
  width: '100%',
  textTransform: 'none',
  borderRadius: '2rem',
  height: '3rem'
}

const BuyCoursePage = () => {

  let token = useSelector(state => state.User?.jwt_token);
  let course_detail = useSelector(state => state.Course?.course_detail);
  let purchase_url = useSelector(state => state.Course?.purchase_url);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { courseId,id } = useParams();



  useEffect(() => {

    dispatch(CourseDetailFunction({
      course_id: `${id}` 
     }))

  },[])


  useEffect(() => {

   

    if(purchase_url != '')
      window.open(`${purchase_url}`, '_blank');

  },[course_detail,purchase_url])


 const BuyCourseFunction = async () => {

  
  if(token === null){
    alert('You require to SignIn First')
    navigate("/signin"); 
  }
  else{
    const response = await dispatch(CoursePaymentFunction({
     token,
     course_id: `${courseId}` 
    }))


  }
    


 } 
 


 return (
  <>
   <Grid container xs={12} sm={12} md={12}>
    <AppBar position="static" sx={Navbar}>
      <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1}}>
          SpringBoard E-Learning Platform
        </Typography>
        <Typography variant="body1" sx={MyCourseButton}>
          My Course
        </Typography>
        <IconButton color="inherit">
          <AccountCircle sx={LoginLogoStyle}/> {/* This is the additional button */}
        </IconButton>
      </Toolbar>
    </AppBar>  
    <Grid container xs={12} sm={12} md={12} sx={MainContainer}>
     <Grid container xs={12} sm={12} md={12} sx={BannerContainer}>
      <Grid item xs={12} sm={12} md={12} sx={HeaderTextContainer}>
       <Typography sx={TitleTextStyle}> fgbfgbh</Typography>
       <Typography sx={SubTitleTextStyle}>fgbfg</Typography>
      </Grid>
     </Grid>
     <Grid container xs={12} sm={12} md={12}>
      <Grid item xs={12} sm={8} md={8}>
    
      </Grid>
      <Grid item xs={12} sm={4} md={4} sx={CourseBoxContainer}>
      <Grid item xs={12} sm={12} md={12} sx={CourseOuterContainer}>
       <Grid item xs={12} sm={12} md={12} sx={CourseContainer}>
        <Box
        component="img"
        sx={{
         height: '19rem',
         width: '100%',
         borderRadius: '1.4rem 1.4rem 0 0'  
        //  maxHeight: { xs: 233, md: 167 },
        //  maxWidth: { xs: 350, md: 250 },
        }}
        alt="DataStructure"
        src="sq-2.jpg"
       />
       <Typography sx={CourseHeaderStyle}>$gbf</Typography>
       {/* <img src={CourseImage} sx={CourseImageStyle} /> */}
       <Grid container xs={12} sm={12} md={12} sx={LastSectionContainer}>
         <Grid item xs={12} sm={12} md={12} sx={SecondLastContainer}>
         <Button variant="contained" sx={BuyButtonStyle}  color="success" onClick={BuyCourseFunction}><Typography sx={BuyNowButtonTextStyle}>Buy Now</Typography></Button>
         </Grid>
         <Grid item xs={12} sm={12} md={12} sx={FirstLastContainer}>
          <Typography sx={CourseCompleteTextStyle}>What’s included</Typography>
          <ul>
           <li><Typography sx={ListTextStyle}>20.2 hours on-demand video</Typography></li>
           <li><Typography sx={ListTextStyle}>80 Lessons</Typography></li>
           <li><Typography sx={ListTextStyle}>Certificate of completion</Typography></li>
          </ul>
         </Grid>
         </Grid>
        </Grid>
       </Grid>
      </Grid>
     </Grid>
    </Grid> 
   </Grid>  
  </>  
 )   
}

export default BuyCoursePage;