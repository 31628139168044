import { memo, useRef,useEffect,useState ,useContext} from "react";
import { Link } from "react-router-dom";
import {
  Box,
  styled,
  useTheme,
  MenuItem,
  IconButton,
  useMediaQuery,
  Button,
  Tooltip,
  Badge,
  Popover,
  Typography,
  List, ListItem, ListItemText, CssBaseline,
  Grid,
  Divider
} from "@mui/material";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PsychologyIcon from '@mui/icons-material/Psychology';

import { useSelector,useDispatch } from 'react-redux';
import { logoutFunction } from '../../Redux/slices/UserSlice/UserAPI';
import { useNavigate,useLocation } from 'react-router-dom';
import { CartContext } from '../ShoppingCart/CartContext';
import {clearState} from '../../Redux/slices/CourseSlice/CourseSlice'
// STYLED COMPONENTS
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary
}));
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
const TopbarRootFirstHeader = styled("div")({
    position:'fixed',
  top: 0,
  zIndex: 96,
  height: 40,
  width:"100%",
  //boxShadow: themeShadows[8],
  transition: "all 0.3s ease",
  whiteSpace: "nowrap",
  overflow: "hidden",
  
});

const TopbarContainerFirstHeader = styled(Box)(({ theme }) => ({
  padding: "5px",
  paddingLeft: 18,
  paddingRight: 20,
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  background: "#275317",
  // backgroundImage:'url("/TopContainer.png")',
  [theme.breakpoints.down("sm")]: { paddingLeft: 16, paddingRight: 16 },
  [theme.breakpoints.down("xs")]: { paddingLeft: 14, paddingRight: 16 }
}));

const TopbarRootMiddleHeader = styled("div")({
  position:'fixed',
  top: 0,
  zIndex: 96,
  width:"100%",
  height: 64,
 // boxShadow: themeShadows[8],
  transition: "all 0.3s ease",
  whiteSpace: "nowrap",
  overflow: "hidden",
});

const TopbarContainerMiddleHeader = styled(Box)(({ theme }) => ({
  padding: "8px",
  paddingLeft: 18,
  paddingRight: 20,
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundImage:   'url("/MiddleContainer.png")',
  // background: "#3B7D23",
  [theme.breakpoints.down("sm")]: { paddingLeft: 16, paddingRight: 16 },
  [theme.breakpoints.down("xs")]: { paddingLeft: 14, paddingRight: 16 }
}));

const TopbarRoot = styled("div")({
  position:'fixed',
  top: 0,
  zIndex: 96,
  width:"100%",
  height: 40,
//  boxShadow: themeShadows[8],
  transition: "all 0.3s ease",
  whiteSpace: "nowrap",
  overflow: "hidden",
});

const TopbarContainer = styled(Box)(({ theme }) => ({
  padding: "8px",
  paddingLeft: 18,
  paddingRight: 20,
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  // background: "#275317",
  backgroundImage:   'url("/Gradient.png")',
  [theme.breakpoints.down("sm")]: { paddingLeft: 16, paddingRight: 16 },
  [theme.breakpoints.down("xs")]: { paddingLeft: 14, paddingRight: 16 }
}));

const UserMenu = styled(Box)({
  padding: 4,
  display: "flex",
  borderRadius: 24,
  cursor: "pointer",
  alignItems: "center",
  "& span": { margin: "0 8px" }
});

const StyledItem = styled(MenuItem)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  minWidth: 185,
  "& a": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    textDecoration: "none"
  },
  "& span": { marginRight: "10px", color: theme.palette.text.primary }
}));

const IconBox = styled("div")(({ theme }) => ({
  display: "inherit",
  [theme.breakpoints.down("md")]: { display: "none !important" }
}));

const Layout1Topbar = ({ coursesRef,curriculumRef,navData,cartData,jobTrainingRef}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const location = useLocation();
  // const open = Boolean(anchorEl);
  const [ cartLength,setCartLength] =useState(0)
  let purchased_course_data = useSelector(state => state.Course?.purchased_course_data);
  let course_data = useSelector(state => state.Course?.course_data);
  const { cartItems } = useContext(CartContext);
  const [anchorEl, setAnchorEl] = useState(null);

console.log('in layour......................',cartItems.length)

  const [userPresent,setUserPresent] =useState(false)
    const [myCoursePath,setMyCoursePath] = useState(false)
    const categories = [
      {
        title: 'Software Development',
        items: ['Databases', 'Game Development', 'Mobile Development', 'Programming Languages', 'Web Development'],
      },
      {
        title: 'Security',
        items: [
          'Certifications',
          'Governance, Risk & Compliance',
          'Security Architecture & Engineering',
          'Security Operations',
          'Security Testing',
        ],
      },
      {
        title: 'Data & Machine Learning',
        items: ['Big Data', 'Business Intelligence', 'Data Visualization', 'Databases', 'Languages & Libraries', 'Machine Learning'],
      },
      {
        title: 'Cloud',
        items: ['Cloud Architecture & Design', 'Cloud Platforms', 'Salesforce CRM'],
      },
      {
        title: 'IT Ops',
        items: ['Client Operating Systems', 'Collaboration Platforms', 'Configuration Management', 'Containers', 'IT Automation'],
      },
      {
        title: 'Business Professional',
        items: ['Office Applications', 'Security Awareness'],
      },
    ];
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  useEffect(() => {
  
    if(location.pathname ==='/mycourse'){
     
      setMyCoursePath(true)
    }
    let jwt_token = localStorage.getItem('jwt_token_springboard')
    let cart_data_length =localStorage.getItem('cart_data_length');
    
    if(cart_data_length){
      setCartLength(cart_data_length)
    }
   

    if(jwt_token == null){
        setUserPresent(false)
    }else if(jwt_token){
        setUserPresent(true)
    }
    
   },[cartLength]) 
   
  const handleClickOfSignin =()=>{
    navigate('/signin')
  }
  const handleClickOfImage =()=>{
    navigate('/')
  }
  const handleClickOfShoppingCart = (cartData) => {

    navigate('/cart', { state: { cartData } })
  }
  const handleClickOfMyCourse =()=>{

    navigate('/mycourse')
  }
  const handleClickOfIcon = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickOfElearning = async () =>{
    navigate('/')
  }
  const handleCloseOfLogoutIcon = async () => {
    setAnchorEl(null);
   
    dispatch(clearState());    
     const response = await dispatch(logoutFunction());


     if(response.payload.data.status ==="200"){
      localStorage.clear();
      sessionStorage.clear();
      navigate('/signin')
     }
  };
  const handleCloseOfIcon = async () =>{
    setAnchorEl(null);
  }
  const handleScrollTo = (ref) => {
   
      if(ref === undefined){
        navigate('/')
      }else{
        ref.current.scrollIntoView({ behavior: "smooth" });
      }
  };
  const handleClickOfList = (item) => {
    console.log(`Clicked on ${item}`);
    // You can navigate to another page, open a modal, or perform any action here
  };
  return (
    <>
      <TopbarRootFirstHeader sx={{ top: 0 }}>
        <TopbarContainerFirstHeader >
          <Box display="flex">
            <IconBox>
              <StyledIconButton>
                <b style={{ fontSize: "15px", color: "white" }}>School Districts</b>
              </StyledIconButton>

              <StyledIconButton>
                <b style={{ fontSize: "15px", color: "white" }}>University C/STEP</b>
              </StyledIconButton>

              <StyledIconButton>
                <b style={{ fontSize: "15px", color: "white" }}>Community &nbsp;(CBOs)</b>
              </StyledIconButton>
            </IconBox>
          </Box>
        </TopbarContainerFirstHeader>
      </TopbarRootFirstHeader>
      <TopbarRootMiddleHeader sx={{ top: "40px" }}>
        <TopbarContainerMiddleHeader>
          <Box display="flex">
            <IconBox>
              <StyledIconButton onClick={handleClickOfElearning}>
                <img
                  style={{ width: "30px", height: "30px" ,marginRight:'9px'}}
                  src="/SBlogowhitenew.png"
                ></img>

                <b style={{ fontSize: "17px", color: "white" }}> eLearning</b>
              </StyledIconButton>
            </IconBox>
          </Box>
          <Box display="flex">
            <IconBox>
              <StyledIconButton onClick={() => handleScrollTo(coursesRef)}>
                <b style={{ fontSize: "17px", color: "white" }}>All Courses</b>
              </StyledIconButton>

              <StyledIconButton>
                <b
                  style={{ fontSize: "17px", color: "white" }}
                  onClick={() => handleScrollTo(jobTrainingRef)}
                >
                  Job Training
                </b>
              </StyledIconButton>
              <StyledIconButton>
                <b
                  style={{ fontSize: "17px", color: "white" }}
                    onClick={() => handleScrollTo(curriculumRef)}
                >
                  Curriculum
                </b>
              </StyledIconButton>
            </IconBox>
          </Box>
          <Box>
            <IconBox>
              <Button  sx={{
               
               // backgroundColor: "gainsboro",
                borderColor: "white",
                color: "white",
                // "&:hover": {
                //   backgroundColor: "#76c76b"
                // }
              }} variant="outlined">Business Consulting</Button>
            </IconBox>
          </Box>
          <Box display="flex">
            <img
              style={{  height: "46px" }}
              src="WhiteSpringBoardLogo.png"
            ></img>
          </Box>
        </TopbarContainerMiddleHeader>
      </TopbarRootMiddleHeader>
      <TopbarRoot sx={{ top: "100px" }}>
        <TopbarContainer>
          <Box display="flex">
            <IconBox></IconBox>
          </Box>
          <Box display="flex" alignItems="center">
            <IconBox>
           
            <Tooltip title="View Cart">
            <StyledIconButton style={{marginRight:'4px'}}>
             <Badge sx={{
              '& .MuiBadge-badge': {
                backgroundColor: 'white',  // Badge background color
                color: 'black',            // Badge text color
              },
            }} badgeContent={cartItems.length}>
           <ShoppingCartIcon  onClick={() => handleClickOfShoppingCart(cartData)} style={{color:'white'}}/>
            </Badge>
          
       {/* {
        ((navData ==0 || navData == undefined) && (cartLength ==0 || cartLength==undefined) )&&  
        <ShoppingCartIcon onClick={() => handleClickOfShoppingCart(cartData)} style={{color:'white'}}/>
       
       } */}
            
            </StyledIconButton>
            </Tooltip>
            {userPresent=== false || myCoursePath=== true? '': 
            <StyledIconButton onClick ={handleClickOfMyCourse}>
                <b style={{ fontSize: "15px", color: "white" }}>My Course</b>
              </StyledIconButton>}
              <div>
               
              {/* <StyledIconButton  onClick={handleClick} >
              
              <b style={{ fontSize: "15px", color: "white" }}>Products  </b>
               <span style={{color:'white',fontSize: "15px"}}> {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
             
              </StyledIconButton> */}
            <StyledIconButton onClick={handleClick} style={{ display: 'flex', alignItems: 'center' }}>
            <b style={{ fontSize: "15px", color: "white", lineHeight: '1.5' }}>Products</b>
            <span style={{ color: 'white', fontSize: "15px", display: 'flex', alignItems: 'center' }}>
              {open ? <KeyboardArrowUpIcon style={{ verticalAlign: 'middle' }} /> : <KeyboardArrowDownIcon style={{ verticalAlign: 'middle' }} />}
            </span>
          </StyledIconButton>
              </div>
              <StyledIconButton>
                <b style={{ fontSize: "15px", color: "white" }}>Contact Us</b>
              </StyledIconButton>
              {
              userPresent ===false ?   
        
              <StyledIconButton onClick={handleClickOfSignin}>
                <b style={{ fontSize: "15px", color: "white" }}>Sign In</b>
              </StyledIconButton>
              : <StyledIconButton onClick={handleCloseOfLogoutIcon}>
              <b style={{ fontSize: "15px", color: "white" }}>Logout</b>
            </StyledIconButton>}
            </IconBox>
          </Box>
        </TopbarContainer>
      </TopbarRoot>
      {open && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backdropFilter: 'blur(8px)',  // Apply background blur
            zIndex: 1,  // Make sure it's behind the modal
            backgroundColor: 'rgba(0, 0, 0, 0.5)',  // Semi-transparent black overlay
          }}
        />
      )}
     <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: '1200px',
            padding: '16px',
            zIndex: 2,  // Make sure it's above the blurred background
          },
        }}
      >
        <Box display="flex" justifyContent="space-around">
          {/* Column 1 */}
          <Grid container spacing={6}>
        <Grid item lg={4} md={4} sm={12} xs={12}>
        <div style={{ display: "flex" }}>
                {" "}
                <PsychologyIcon fontSize="large" />
                <Typography gutterBottom variant="h6" component="div">
                  <b>Software Engineering</b>
                </Typography>
              </div>
              <Divider />
              <List>
                {/* <ListItem><ListItemText primary="Data Structures & Algorithm" /></ListItem>
                <ListItem><ListItemText primary="Cyber Security For Everyone" /></ListItem>
                <ListItem><ListItemText primary="Database Design & Development" /></ListItem>
                <ListItem><ListItemText primary="Animation For Begineers" /></ListItem>
                <ListItem><ListItemText primary="Advance Animation" /></ListItem>
                <ListItem><ListItemText primary="Level Up Entrepreneurs" /></ListItem>
                <ListItem><ListItemText primary="Artificial Intelligence" /></ListItem>
                <ListItem><ListItemText primary="Machine Learning And Algorithms" /></ListItem>
                <ListItem><ListItemText primary="Deep Learning & Neural Networks" /></ListItem> */}
                          {[
                  "Data Structures & Algorithm",
                  "Cyber Security For Everyone",
                  "Database Design & Development",
                  "Animation For Beginners",
                  "Advance Animation",
                  "Level Up Entrepreneurs",
                  "Artificial Intelligence",
                  "Machine Learning And Algorithms",
                  "Deep Learning & Neural Networks"
                ].map((text) => (
                  <ListItem button key={text} onClick={() => handleClickOfList(text)}>
                    <ListItemText primary={text} />
                  </ListItem>
                ))}
              </List>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
        <div style={{ display: "flex" }}>
                {" "}
                <PsychologyIcon fontSize="large" />
                <Typography gutterBottom variant="h6" component="div">
                  <b>Job Training</b>
                </Typography>
              </div>
              <Divider />
              <List>
                <ListItem><ListItemText primary="Enginnering Test Technician" /></ListItem>
                <ListItem><ListItemText primary="Structured & Network Cabling" /></ListItem>
                <ListItem><ListItemText primary="J-STD-0001 Certification" /></ListItem>
             
              </List>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
        <div style={{ display: "flex" }}>
                {" "}
                <PsychologyIcon fontSize="large" />
                <Typography gutterBottom variant="h6" component="div">
                  <b>Curriculum</b>
                </Typography>
              </div>
              <Divider />
              <List>
                <ListItem><ListItemText primary="Lesson Plans" /></ListItem>
                <ListItem><ListItemText primary="Instructor Resources" /></ListItem>
                <ListItem><ListItemText primary="Teacing Observation" /></ListItem>
                {/* <ListItem><ListItemText primary="Programming Languages" /></ListItem>
                <ListItem><ListItemText primary="Web Development" /></ListItem> */}
              </List>
        </Grid>
      
      </Grid>
        </Box>
      </Popover>
    </>
  );
};

export default memo(Layout1Topbar);
