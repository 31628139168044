import { Fragment } from "react";
import {
  Card,
  Grid,
  styled,
  useTheme,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Paper
} from "@mui/material";
import MemoryIcon from "@mui/icons-material/Memory";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import PeopleIcon from "@mui/icons-material/People";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
 import CourseLayout from "./CourseLayout";
const ContentBox = styled("div")(({ theme }) => ({
  margin: "7%",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));


export default function CirruculumLayout() {
  const { palette } = useTheme();

  return (
    <Fragment>
      <ContentBox>
        <h1 style={{ textAlign: "center" }}>SPRINGBOARD CURRICULUM OFFERING</h1>

        <Card sx={{ border: "3px solid black", borderRadius: "0px", padding: "16px" }}>
          <CardContent>
            <Grid container spacing={4}>
              {/* First section (4 columns) */}
              <Grid item xs={12} sm={4}>
                <div style={{ display: "flex", gap: "8px" }}>
                  <AutoStoriesIcon fontSize="large" />
                  <Typography gutterBottom variant="h5" component="div">
                    <b>LESSON PLANS</b>
                  </Typography>
                </div>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  
                Engage your students with structured, easy-to-follow lesson plans designed to maximize learning outcomes. Each plan includes clear objectives, step-by-step activities, and practical exercises, ensuring a comprehensive and impactful educational experience.
                </Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <div style={{ display: "flex", gap: "8px" }}>
                  <LibraryBooksIcon fontSize="large" />
                  <Typography gutterBottom variant="h5" component="div">
                    <b>INSTRUCTOR RESOURCES</b>
                  </Typography>
                </div>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Empower your teaching with our rich set of instructor resources. Access detailed guides, expert tips, and adaptable materials to enhance your classroom delivery and keep your students engaged and inspired.
                </Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <div style={{ display: "flex", gap: "8px" }}>
                  <PeopleIcon fontSize="large" />
                  <Typography gutterBottom variant="h5" component="div">
                    <b>TEACHING OBSERVATION</b>
                  </Typography>
                </div>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Receive valuable insights with our teaching observation tools. We provide constructive feedback and best practices to refine your teaching techniques, improve student engagement, and elevate your overall effectiveness in the classroom.
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ justifyContent: "center" }}>
            <Button
              sx={{
                width: "100%",
                backgroundColor: "gainsboro",
                borderColor: "green",
                color: "green",
                "&:hover": {
                  backgroundColor: "#76c76b"
                }
              }}
              color="success"
              variant="outlined"
              size="small"
            >
              Learn More
            </Button>
          </CardActions>
        </Card>
      </ContentBox>
    </Fragment>
  );
}
