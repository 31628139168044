import { createSlice } from '@reduxjs/toolkit';
import { 
  SignUpFunction,
  SignInFunction,
  ConfirmAccountFunction,
  SignInGoogleFunction,
  ForgotPasswordOtpFunction,
  ForgotPasswordOtpConfirmFunction,
  ForgetPasswordChangePasswordFunction,
  logoutFunction
} from './UserAPI';

const initialState = {
  loading: false,
  message: null,
  sigupErrorArray: [],
  user_data: {},
  jwt_token: null,
  isAuthenticated: false,
  success: false  
}

export const UserSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    resetSignUp: (state) => {
      state.success = '';
      state.message='';
      state.status='';
      state.sigupErrorArray = []
    },
    resetSuccess: (state) => {
      state.success = false;
    }
  },
  
  extraReducers: (builder) => {
    builder
      .addCase(SignUpFunction.pending, (state) => {
      
        state.loading = true;
      })
      .addCase(SignUpFunction.fulfilled, (state, action) => {
      
        state.loading = false;
        state.message = `${action.payload?.message}`
        state.success = `${action.payload?.success}`
        state.status=`${action.payload?.status}`
        return;
      })
      .addCase(SignUpFunction.rejected, (state, action) => {
      
        state.loading = false;
        state.user_data = {};
        state.sigupErrorArray = action.payload?.error;
        state.success = false;
        
      })
      .addCase(SignInFunction.pending, (state) => {
    
        state.loading = true;
      })
      .addCase(SignInFunction.fulfilled, (state, action) => {
      
        state.loading = false;
        state.jwt_token = `${action.payload?.jwt_token}`;
        state.user_data =  `${action.payload?.user._id}`;
        localStorage.setItem('jwt_token_springboard', action?.payload?.jwt_token);
        state.success = true
        state.isAuthenticated = true
      })
      .addCase(SignInFunction.rejected, (state, action) => { 
      
        state.loading = false;
        state.user_data = {};
        state.message = action.payload?.error;
        state.success = false;
        state.isAuthenticated = false
      })
      .addCase(SignInGoogleFunction.pending, (state) => {
      
        state.loading = true;
      })
      .addCase(SignInGoogleFunction.fulfilled, (state, action) => {
      
        state.loading = false;

      })
      .addCase(SignInGoogleFunction.rejected, (state, action) => { 
      
        state.loading = false;
      }).
      addCase(ConfirmAccountFunction.pending, (state) => {
      
        state.loading = true;
        state.success = false;
      })
      .addCase(ConfirmAccountFunction.fulfilled, (state, action) => {
      
        state.loading = false;
        state.success = true;
        state.message = `${action.payload?.data.message}`
      
      })
      .addCase(ConfirmAccountFunction.rejected, (state, action) => { 
     
        state.loading = false;
        state.message = `${action.payload?.data.response}`
        state.success = false
      })

      .addCase(ForgotPasswordOtpFunction.pending, (state) => {
    
        state.loading = true;
        state.success = false;
      })
      .addCase(ForgotPasswordOtpFunction.fulfilled, (state, action) => {
    
        state.loading = false;
        state.otpSend = true;
        state.otpConfirm = false;
        state.token = `${action.payload?.data.token}`
        state.message = `${action.payload?.data.response}`;
        state.success = `${action.payload?.data.success}`;
        //state.jwt_token = `${action.payload?.data.jwt_token}`
        //localStorage.setItem('jwt_token_springboard', action?.payload?.data.jwt_token);
        //state.success = true
        //state.isAuthenticated = true
      })
      .addCase(ForgotPasswordOtpFunction.rejected, (state, action) => { 
      
        state.loading = false;
        state.message = `${action.payload?.data.response}`;
        state.success = `${action.payload?.data.success}`

      
      })


      .addCase(ForgotPasswordOtpConfirmFunction.pending, (state) => {
        
        state.loading = true;
        state.success = false;
      })
      .addCase(ForgotPasswordOtpConfirmFunction.fulfilled, (state, action) => {
      
       
        state.loading = false;
        state.otpConfirm = `${action.payload?.data.otpConfirm}`;
        state.message = `${action.payload?.data.message}`;
        state.success = `${action.payload?.data.success}`;
        //state.jwt_token = `${action.payload?.data.jwt_token}`
        //localStorage.setItem('jwt_token_springboard', action?.payload?.data.jwt_token);
        //state.success = true
        //state.isAuthenticated = true
      })
      .addCase(ForgotPasswordOtpConfirmFunction.rejected, (state, action) => { 
      
        state.loading = false;
        state.message = `${action.payload?.data.response}`
        state.success = false
        
      })




      .addCase(ForgetPasswordChangePasswordFunction.pending, (state) => {
       
        state.loading = true;
        state.success = false;
      })
      .addCase(ForgetPasswordChangePasswordFunction.fulfilled, (state, action) => {
       
        state.loading = false;
        state.passwordConfirm = true;
        state.message = `${action.payload?.data.message}`;
        state.success = true;
        //state.jwt_token = `${action.payload?.data.jwt_token}`
        //localStorage.setItem('jwt_token_springboard', action?.payload?.data.jwt_token);
        //state.success = true
        //state.isAuthenticated = true
      })
      .addCase(ForgetPasswordChangePasswordFunction.rejected, (state, action) => { 
     
        state.loading = false;
        state.message = `${action.payload?.data.response}`
        state.success = false
      })


      .addCase(logoutFunction.pending, (state) => {
     
        state.loading = true;
        state.success = false;
      })
      .addCase(logoutFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.otpConfirm = true;
        state.message = `${action.payload?.data.message}`;
        state.success = true;
        //state.jwt_token = `${action.payload?.data.jwt_token}`
        //localStorage.setItem('jwt_token_springboard', action?.payload?.data.jwt_token);
        //state.success = true
        //state.isAuthenticated = true
      })
      .addCase(logoutFunction.rejected, (state, action) => { 
       
        state.loading = false;
        state.message = `${action.payload?.data.response}`
        state.success = false
      })

  },
})

// Action creators are generated for each case reducer function
// export const { SignUpFunction } = UserSlice.actions
export const { resetSignUp ,resetSuccess} = UserSlice.actions;

export default UserSlice.reducer

