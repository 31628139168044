import { Fragment,useState,useEffect,useContext } from "react";
import {
  Card,
  Grid,
  styled,
  useTheme,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Box
} from "@mui/material";
import { CourseListFunction,CourseAllListFunction } from '../../Redux/slices/CourseSlice/CourseAPI';
import MemoryIcon from "@mui/icons-material/Memory";
import { useSelector,useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CartContext } from '../ShoppingCart/CartContext';
// STYLED COMPONENTS
const ContentBox = styled("div")(({ theme }) => ({
  margin: "7%",
 
  //[theme.breakpoints.down("sm")]: { margin: "16px" }
  [theme.breakpoints.down("md")]: { margin: "12%" },
  [theme.breakpoints.down("sm")]: { margin: "7%" },
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  marginRight: ".5rem",
  textTransform: "capitalize"
}));

const SubTitle = styled("span")(({ theme }) => ({
  fontSize: "0.875rem",
  color: theme.palette.text.secondary
}));

const H4 = styled("h4")(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: "500",
  marginBottom: "16px",
  textTransform: "capitalize",
  color: theme.palette.text.secondary
}));

export default function CourseLayout({setNavData,setCartData}) {
 
  const { palette } = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let purchased_course_data = useSelector(state => state.Course?.purchased_course_data);
  let course_data = useSelector(state => state.Course?.course_data);
  const [courseData,setCourseData]=useState()
  const [userPresent,setUserPresent]=useState(false);
  const [open, setOpen] = useState(false);
  const [openRemove,setOpenRemove]= useState(false);
  const [addedToCart,setAddedToCart] = useState([]);
  const [cart, setCart] = useState([]);
  const [cartDataFromLocal, setCartDataFromLocal] = useState([]);
  const [cartLength, setCartLength] = useState(0);
  const { cartItems, addToCart,removeFromCart } = useContext(CartContext);
  let heading = "SPRINGBOARD ONLINE COURSES"
  let jwt_token = localStorage.getItem('jwt_token_springboard')
  console.log('jwt token')
  console.log('purchased_course_data',purchased_course_data)
  useEffect(() => {
   
    let cart_data= localStorage.getItem('cart_data');

    let cart_data_length= localStorage.getItem('cart_data_length');
    if(cart_data_length>0){
      setCartDataFromLocal(cart_data)
      setCartLength(cart_data_length)
      setCart(JSON.parse(cart_data));
      setCartData(JSON.parse(cart_data)); 
    }
    const fetchData = async () => {
      if (!courseData || courseData.length === 0) {
        const response = await dispatch(CourseListFunction());
 
        setCourseData(response.payload.data.Course_list);
      }
    };
    const fetchAllData = async () => {
      
        const response = await dispatch(CourseAllListFunction());
        setCourseData(response.payload.data.Course_list);
      
    };
    if (!jwt_token && course_data &&  !courseData) {
      fetchAllData();
     // window.location.reload()
    }else 
    if(jwt_token==null && courseData==undefined){
  
      navigate('/signin')
    }else
    if (jwt_token && course_data && !courseData) {
      fetchData();
    }  
  }, [dispatch,navigate]);


  const handleClickofCardMedia=(courseDetails)=>{
    console.log('course details in click of card media',courseDetails)
    navigate('/courseDetail',{state:{courseDetails}})
  }

const handleClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setOpen(false);
};
const handleCartToggle = (course) => {
  console.log('cart in cart toggle',cart)
 
  
  if (cart.some(item => item.course_id === course.course_id)) { 
    removeFromCart(course)
    const updatedCart = cart.filter(item => item.course_id !== course.course_id);
    console.log('updatedcart',updatedCart)
    setCart(updatedCart);
    setCartData(updatedCart); 
    setOpenRemove(true);
    setNavData(updatedCart.length); 
    
   
  } else {
   
    addToCart(course)
    const updatedCart = [...cart, course];

    setCart(updatedCart);
    setCartData(updatedCart); 
    setOpen(true);
    setNavData(updatedCart.length);   
  }
};
const handleClickOfStartButton = ()=>{
navigate('/myCourse')
}
  return (
    <Fragment>
      <ContentBox>
      <h1 style={{ textAlign: "center" }}><u>{heading}</u></h1>
<br></br>
      <Grid container spacing={6}>
      
           {  jwt_token == null && courseData && courseData.map((course, index) => (
       course &&  course.type=='All' &&    <Grid item lg={4} md={4} sm={12} xs={12}>
          <Card sx={{ border: "3px solid black", borderRadius: "0px", padding: "16px",minHeight: "505px" }}>
            <CardMedia
              sx={{ height: 200,cursor:"pointer"}}
              image={course.course_picture}
              onClick={() => handleClickofCardMedia(course)}
              title={course.course_name}
            />
            <CardContent>
              <div style={{ display: "flex" }}>
                {" "}
                <MemoryIcon fontSize="large" />
                <Typography sx={{ cursor: "pointer", minHeight: "55px" }}   onClick={() => handleClickofCardMedia(course)} gutterBottom variant="h5" component="div">
                <b>{course.course_name.toUpperCase()}</b> 
                </Typography>
              </div>
          
       
<div>

<Typography
  sx={{
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "4",
    WebkitBoxOrient: "vertical",
    
  }}>
 {course.course_description} </Typography>
<a style={{cursor:"pointer"}} onClick={() => handleClickofCardMedia(course)}>read more</a>
</div>

              <br></br>
              <Typography >
                <b>- Dr. Steven Lindo, Chairman and CEO</b>
              </Typography>
            </CardContent>
            
            <CardActions>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <Typography sx={{ ml: 1,fontSize: '1.1rem',color:'dimgrey' }}>${course.price}</Typography>
              <Button 
                color="success" 
                variant="outlined" 
                size="small" 
                onClick={() => handleCartToggle(course)}
              >
                {cartItems.some(item => item.course_id === course.course_id) ? 'Remove From Cart' : 'Add to Cart'}
              </Button>
            </Box>
          </CardActions>

          </Card>
        </Grid>
  ))}
  {jwt_token  && courseData && courseData.coursesWithPurchasedDetails && courseData.coursesWithPurchasedDetails
      .map((course, index) => (
        course &&  course.type=='All' &&   <Grid item lg={4} md={4} sm={12} xs={12}>
          <Card sx={{ border: "3px solid black", borderRadius: "0px", padding: "16px" ,minHeight: "505px"}}>
            <CardMedia sx={{ height: 200,cursor:"pointer" }} image={course.course_picture} title="green iguana" onClick={() => handleClickofCardMedia(course)}/>
            <CardContent>
              <div style={{ display: "flex" }}>
                {" "}
                <MemoryIcon fontSize="large" />
                <Typography  sx={{ cursor: "pointer",minHeight: "55px" }}  onClick={() => handleClickofCardMedia(course)} gutterBottom variant="h5" component="div">
                <b>{course.course_name.toUpperCase()}</b> 
                </Typography>
              </div>

              <Typography variant="body2"  sx={{ color: "text.secondary",minHeight: "80px",  // Adjust this height based on your needs
        maxHeight: "122px",
        overflow: "hidden",  // Optional: hides overflow text if it exceeds height
        textOverflow: "ellipsis"
         }}>
              {course.course_description} 
              </Typography>
              <br></br>
              <Typography >
                <b>Dr. Steven Lindo, Chairman and CEO</b>
              </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: "right" }}>
              <Button onClick={handleClickOfStartButton} color="success" variant="outlined" size="small">
              Continue Learning
              </Button>
            </CardActions>
          </Card>
        </Grid>
         ))}

{jwt_token  && courseData && courseData.coursesWithoutPurchasedDetails
      .map((course, index) => (
        course &&  course.type=='All' &&   <Grid item lg={4} md={4} sm={12} xs={12}>
           <Card sx={{ border: "3px solid black", borderRadius: "0px", padding: "16px",minHeight: "505px" }}>
            <CardMedia
              sx={{ height: 200 ,cursor:"pointer"}}
              image={course.course_picture}
              title="green iguana"
              onClick={() => handleClickofCardMedia(course)}
            />
            <CardContent>
              <div style={{ display: "flex" }}>
                {" "}
                <MemoryIcon fontSize="large" />
                <Typography  sx={{ cursor: "pointer",minHeight: "55px" }}  onClick={() => handleClickofCardMedia(course)} gutterBottom variant="h5" component="div">
                <b>{course.course_name.toUpperCase()}</b> 
                </Typography>
              </div>
              <Typography variant="body2"  sx={{ color: "text.secondary",minHeight: "80px",  // Adjust this height based on your needs
        maxHeight: "90px",
        overflow: "hidden",  // Optional: hides overflow text if it exceeds height
        textOverflow: "ellipsis"
         }}>
              {course.course_description}
              </Typography>
              <br></br>
              <Typography >
                <b>Dr. Steven Lindo, Chairman and CEO</b>
              </Typography>
            </CardContent>
            
            <CardActions>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <Typography sx={{ ml: 1,fontSize: '1.1rem',color:'dimgrey' }}>${course.price}</Typography>
              <Button 
                color="success" 
                variant="outlined" 
                size="small" 
                onClick={() => handleCartToggle(course)}
              >
                {/* {cartLength >0 && cartDataFromLocal.some(item => item.course_id === course.course_id) ? 'Remove From Cart' : 'Add to Cart'} */}
                {cart.some(item => item.course_id === course.course_id) ? 'Remove From Cart' : 'Add to Cart'}
              </Button>
            </Box>
          </CardActions>

          </Card>
        </Grid>
         ))}

      </Grid>

   
      </ContentBox>
    </Fragment>
  );
}
