import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { store,persistor } from './Redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { GoogleOAuthProvider } from "@react-oauth/google"


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId="77886489361-vaclh5ha3vd8t1c26efu13t2f0l9f6bu.apps.googleusercontent.com">
   <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
     <BrowserRouter>
      <App />  
     </BrowserRouter>
    </PersistGate> 
   </Provider>   
  </GoogleOAuthProvider>  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
